import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import "../components/blog-styles.scss"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faUser } from "@fortawesome/free-solid-svg-icons"

// import avatar from "../images/blog/avatar-pic.png"

const FeaturedPost = ({ latestItem }) => {
  return (
    <div className="blog-heading col-lg-12 blog-heading-inner">
      <h2 className="text-orange mb-5 mt-0">
        <strong>{latestItem.title}</strong>
        <br />
      </h2>
      <Link className="btn-blog" to={`/${latestItem.slug.current}`}>
        READ MORE
      </Link>
      <div className="row pt-5 align-items-center justify-content-between">
        <div className="d-flex justify-content-center align-items-center">
          <div className="mr-3">
            <img
              loading="lazy"
              src={latestItem.authors[0].person.image.asset.fluid.src}
              style={{ borderRadius: "50%" }}
              alt={latestItem.authors[0].person.name}
            />
          </div>
          <div className="text-white">
            <p className="mb-0">{latestItem.authors[0].person.name}</p>
          </div>
        </div>
        <div>
          <p className="text-white mb-0">{latestItem.publishedAt}</p>
        </div>
      </div>
    </div>
  )
}

class Blog extends React.Component {
  render() {
    const posts = this.props.data.allSanityPost.edges

    return (
      <Layout>
        <SEO
          title="Blog"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
        <div className="blog-wrapper">
          <div className="container-fluid headline"></div>
          <div className="container-fluid page-title mx-auto overflow-hidden">
            <h1 className="text-orange m-0 orbitron-font p-relative mx-auto text-center">
              <strong>OUR BLOG</strong>
            </h1>
          </div>
          <div className="container-fluid main-section">
            <div className="container inner-section">
              <div className="blog-entries">
                <div className="row">
                  {posts.map((post, idx) => {
                    const node = post.node
                    return (
                      <React.Fragment key={idx}>
                        {idx === 0 ? (
                          <FeaturedPost latestItem={node} />
                        ) : (
                          <div className="col-12 col-md-6 col-lg-4 post-box node-box">
                            <Link to={`/${node.slug.current}`}>
                              <div
                                className="blog-entries-container p-relative my-3"
                                style={{
                                  background: `url(${node.mainImage.asset.fluid.src}) no-repeat center`,
                                }}
                              >
                                <div className="blog-details text-white">
                                  <p className="mb-2">{node.title}</p>
                                  <span className="blog-desc">
                                    {node.excerpt}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Blog

export const BlogQuery = graphql`
  query {
    allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      edges {
        node {
          title
          slug {
            current
          }
          publishedAt(formatString: "MMMM DD, YYYY")
          excerpt
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          body
          authors {
            person {
              name
              image {
                asset {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
